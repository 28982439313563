import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "div-information-icon-message" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "information-message" }
const _hoisted_4 = {
  key: 0,
  class: "close-icon"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-bar", [_ctx.localViewModel.InformationType === _ctx.Information ? 'info-type-message' : 'warning-message']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.InformationIcon,
        class: _normalizeClass({'display-none': _ctx.InformationIcon === ''})
      }, null, 10, _hoisted_2),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.localViewModel.Message), 1)
    ]),
    (_ctx.localViewModel.IsCloseIconVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("a", {
            href: "javascript:void(0)",
            "aria-hidden": "true",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
          }, [
            _createElementVNode("img", { src: _ctx.CrossIcon }, null, 8, _hoisted_5)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}