import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_4 = { class: "modal-dialog" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "new-modal-header position-relative" }
const _hoisted_7 = { class: "modal-title text-center font-weight-bold" }
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = {
  id: "downloadimage",
  class: "body-content"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localShowModalPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.localHeaderContent), 1),
                      _createElementVNode("a", {
                        href: "javascript:void(0)",
                        "aria-hidden": "true",
                        class: "cross-icon",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("i", {
                          class: "fa fa-times close-color",
                          "aria-hidden": "true"
                        }, null, -1)
                      ]))
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "body-heading" }, null, -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("img", {
                          src: _ctx.localImageUrl,
                          class: "qr-code-image"
                        }, null, 8, _hoisted_10),
                        _createElementVNode("p", { innerHTML: _ctx.localNoteText }, null, 8, _hoisted_11),
                        _createElementVNode("button", {
                          class: "btn btn-download btn-size-default btn-QrCode",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.DownLoadImage()))
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.localButtonText), 1)
                        ])
                      ])
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "modal-footer" }, null, -1))
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}