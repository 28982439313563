import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withModifiers as _withModifiers, resolveDirective as _resolveDirective, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "div-validation"
}
const _hoisted_4 = { class: "back-navigation-p cursor-pointer" }
const _hoisted_5 = { class: "custom-theme-card" }
const _hoisted_6 = { class: "theme-creation-card" }
const _hoisted_7 = { class: "label-input-div" }
const _hoisted_8 = { class: "label-div" }
const _hoisted_9 = { class: "primary-heading" }
const _hoisted_10 = { class: "input-div" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "label-input-div" }
const _hoisted_13 = { class: "label-div" }
const _hoisted_14 = { class: "primary-heading theme-logo-label" }
const _hoisted_15 = { class: "input-div" }
const _hoisted_16 = { class: "checkbox-container" }
const _hoisted_17 = { class: "description" }
const _hoisted_18 = {
  class: "theme-logo-label cursor-pointer",
  for: "img"
}
const _hoisted_19 = {
  key: 0,
  class: "theme-default-logo-img"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  for: "img",
  class: "add-logo cursor-pointer description"
}
const _hoisted_22 = ["src"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "label-input-div" }
const _hoisted_25 = { class: "label-div" }
const _hoisted_26 = { class: "label-height-div" }
const _hoisted_27 = { class: "primary-heading" }
const _hoisted_28 = { class: "input-div" }
const _hoisted_29 = { class: "radio-button-container" }
const _hoisted_30 = {
  key: 0,
  class: "mono-color-container"
}
const _hoisted_31 = { class: "mono-color-text-container" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = {
  key: 1,
  class: "multi-color-container"
}
const _hoisted_34 = { class: "label-input-div" }
const _hoisted_35 = { class: "label-div" }
const _hoisted_36 = { class: "primary-heading" }
const _hoisted_37 = { class: "input-div" }
const _hoisted_38 = { class: "sub-input-div" }
const _hoisted_39 = { class: "label-input-div" }
const _hoisted_40 = { class: "label-div font-text" }
const _hoisted_41 = { class: "primary-heading" }
const _hoisted_42 = { class: "input-div" }
const _hoisted_43 = { class: "div-palette" }
const _hoisted_44 = { class: "chart-preview-card" }
const _hoisted_45 = { class: "preview" }
const _hoisted_46 = { class: "preview-title" }
const _hoisted_47 = { class: "footer-tab margin-top-20" }
const _hoisted_48 = { class: "parent-div-buttons" }
const _hoisted_49 = ["disabled"]
const _hoisted_50 = { class: "" }
const _hoisted_51 = ["disabled"]
const _hoisted_52 = {
  key: 2,
  class: "event-setting-addicon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toaster = _resolveComponent("Toaster")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Twitter = _resolveComponent("Twitter")!
  const _component_Charts = _resolveComponent("Charts")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["parent-div-custom-theme", {'noClick': _ctx.disablePage}])
  }, [
    (_ctx.showToaster)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Toaster, {
            message: _ctx.toasterMessage,
            "default-timeout": _ctx.toasterDefaultTimeout
          }, null, 8, ["message", "default-timeout"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.showVueModalPopUp)
            ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
                key: 0,
                "modal-pop-up": _ctx.unsavedModalPopUp,
                onClose: _ctx.CloseModelPopup,
                onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveAndSwitchTab($event)))
              }, null, 8, ["modal-pop-up", "onClose"]))
            : _createCommentVNode("", true),
          (_ctx.localEventDetailViewModal.isVisibleValidationSummary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ValidationSummary, {
                  "error-fields": _ctx.localEventDetailViewModal.validationErrorFields,
                  onCloseClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                }, null, 8, ["error-fields"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "back-navigation-div",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.HideCustomThemePanel(false)))
            }, [
              _cache[31] || (_cache[31] = _createElementVNode("i", {
                class: "fa fa-arrow-left back-navigation-i cursor-pointer",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.BackNavigation")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.Name")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.localChartEntity.Name.$model) = $event)),
                      type: "text",
                      class: _normalizeClass([_ctx.status(_ctx.v$.localChartEntity.Name), "name-input-field input-block form-control font-size-13"]),
                      placeholder: _ctx.$t('EventSetting.CustomTheme.NamePlaceholder'),
                      maxlength: "60"
                    }, null, 10, _hoisted_11), [
                      [
                        _vModelText,
                        _ctx.v$.localChartEntity.Name.$model,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      class: _normalizeClass({'label-height-div' : _ctx.localChartEntity.IncludeLogo})
                    }, [
                      _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("Logo")), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("label", _hoisted_17, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localChartEntity.IncludeLogo) = $event)),
                          class: "checkbox",
                          type: "checkbox"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.localChartEntity.IncludeLogo]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.CustomTheme.IncludeLogo")), 1)
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["theme-logo-label-div", [_ctx.localChartEntity.IncludeLogo ? 'show-logo-container' : 'hide-logo-container' ]])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["logo-div cursor-pointer border-radius-10", [_ctx.localChartEntity.LogoImageUrl !=='' || _ctx.localChartEntity.LogoImageUrl !== undefined ? 'solid-border': 'dashed-border',{'logo-error': _ctx.includeLogoError}, {'success-message':_ctx.successMessage}]]),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ClickOnImage(true)))
                      }, [
                        _createElementVNode("label", _hoisted_18, [
                          (_ctx.localChartEntity.LogoImageUrl === null || _ctx.localChartEntity.LogoImageUrl === '')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_20),
                                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("AddLogo")), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: "image-fit",
                                src: _ctx.GetLocalLogoImageUrl(),
                                alt: "Add logo"
                              }, null, 8, _hoisted_22))
                        ])
                      ], 2),
                      _createElementVNode("p", {
                        class: "logo-size-warning small-description",
                        innerHTML: _ctx.GetLogoWarning()
                      }, null, 8, _hoisted_23)
                    ], 2)
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.Colors")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("label", {
                        class: "radio-button-container-one description",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ChangeColorPattern(true)))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localChartEntity.IsMultiColorTheme) = $event)),
                          class: "radio-button",
                          type: "radio",
                          value: true,
                          name: "radioType"
                        }, null, 512), [
                          [_vModelRadio, _ctx.localChartEntity.IsMultiColorTheme]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.CustomTheme.MultipleColors")), 1)
                      ]),
                      _createElementVNode("label", {
                        class: "radio-button-container-two description",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.ChangeColorPattern(false)))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localChartEntity.IsMultiColorTheme) = $event)),
                          class: "radio-button",
                          type: "radio",
                          value: false,
                          name: "radioType"
                        }, null, 512), [
                          [_vModelRadio, _ctx.localChartEntity.IsMultiColorTheme]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.CustomTheme.Monochromatic")), 1)
                      ])
                    ]),
                    (!_ctx.localChartEntity.IsMultiColorTheme)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette1Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[0])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.TogglePalette(0)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette1Color,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.palette1Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("p", {
                              class: "mono-color-instruction",
                              innerHTML: _ctx.$t('EventSetting.CustomTheme.MonochromaticInstruction')
                            }, null, 8, _hoisted_32)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette1Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[0])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.TogglePalette(0)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette1Color,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.palette1Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette2Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[1])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.TogglePalette(1)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette2Color,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.palette2Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette3Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[2])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (_ctx.TogglePalette(2)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette3Color,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.palette3Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette4Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[3])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.TogglePalette(3)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette4Color,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.palette4Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette5Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[4])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (_ctx.TogglePalette(4)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette5Color,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.palette5Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4),
                          _createElementVNode("div", {
                            class: "palette-container",
                            style: _normalizeStyle({'backgroundColor':_ctx.palette6Color.hex})
                          }, [
                            (_ctx.isPaletteSelected[5])
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: "input-block",
                                  onClick: _cache[22] || (_cache[22] = _withModifiers(($event: any) => (_ctx.TogglePalette(5)), ["stop"]))
                                }))
                              : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                  key: 1,
                                  modelValue: _ctx.palette6Color,
                                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.palette6Color) = $event))
                                }, null, 8, ["modelValue"])), [
                                  [_directive_click_outside, _ctx.HideAllPalattes]
                                ])
                          ], 4)
                        ]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("Background")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", {
                        class: "div-palette palette-container",
                        style: _normalizeStyle({'backgroundColor':_ctx.localChartEntity.BackgroundColor})
                      }, [
                        (_ctx.isPaletteSelected[6])
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "input-block",
                              onClick: _cache[24] || (_cache[24] = _withModifiers(($event: any) => (_ctx.TogglePalette(6)), ["stop"]))
                            }))
                          : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                              key: 1,
                              modelValue: _ctx.backgroundColor,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.backgroundColor) = $event))
                            }, null, 8, ["modelValue"])), [
                              [_directive_click_outside, _ctx.HideAllPalattes]
                            ])
                      ], 4),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.Or")), 1),
                      _createElementVNode("label", {
                        class: "btn add-backgroundImage-logo cursor-pointer btn-secondary",
                        for: "BackgroundImg",
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.ClickOnImageBackGround(true)))
                      }, _toDisplayString(_ctx.localChartEntity.BackgroundImageUrl === '' ? _ctx.$t("EventSetting.CustomTheme.AddBackgroundImage") : _ctx.$t("EventSetting.CustomTheme.EditBackgroundImage")), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.FontColor")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", {
                        class: "palette-container",
                        style: _normalizeStyle({'backgroundColor':_ctx.localChartEntity.FontColor})
                      }, [
                        (_ctx.isPaletteSelected[7])
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "input-block",
                              onClick: _cache[27] || (_cache[27] = _withModifiers(($event: any) => (_ctx.TogglePalette(7)), ["stop"]))
                            }))
                          : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                              key: 1,
                              modelValue: _ctx.fontColor,
                              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.fontColor) = $event))
                            }, null, 8, ["modelValue"])), [
                              [_directive_click_outside, _ctx.HideAllPalattes]
                            ])
                      ], 4)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_Charts, { "chart-data": _ctx.localChartEntity }, null, 8, ["chart-data"]),
                _createElementVNode("div", _hoisted_45, [
                  _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.$t("Preview")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_47, [
            _cache[32] || (_cache[32] = _createElementVNode("div", { class: "display-flex" }, [
              _createElementVNode("div", { class: "width100" }, [
                _createElementVNode("hr", { class: "footer-hr margin-top-0" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  class: "btn btn-secondary btn-size-default btn-cancelevent",
                  disabled: !_ctx.isPanelDirty,
                  onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.Discard(_ctx.v$)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
                ], 8, _hoisted_49)
              ]),
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-size-default width100 btn-create-event",
                  disabled: !_ctx.isPanelDirty,
                  onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.Submit()))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
                ], 8, _hoisted_51)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
          _createVNode(_component_ImageProcess, {
            "featured-image-full-src": _ctx.GetfeaturedimageFullUrl(),
            "featuredimage-src": _ctx.GetFeaturedImageUrl(),
            "featured-image-width": _ctx.FeaturedImageWidth,
            "featured-image-height": _ctx.FeaturedImageHeight,
            "module-name": _ctx.moduleName,
            "sub-module-name": _ctx.subModuleName,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageUrl(),
            "show-featured-image": _ctx.showFeaturedImage,
            "only-show-feature-image": _ctx.showFeaturedImage,
            "has-featured-image": _ctx.CheckFeaturedImage(),
            "is-full-image": true,
            onSelectBack: _ctx.ClickOnImage,
            onSetImageIconUrl: _ctx.SetlogoUrl,
            onDiscard: _ctx.SetLogoOldUrl,
            onDiscardFeature: _ctx.SetFeatureOldUrl,
            onSetFeaturedImageIconUrl: _ctx.SetBackgroundImageUrl
          }, null, 8, ["featured-image-full-src", "featuredimage-src", "featured-image-width", "featured-image-height", "module-name", "sub-module-name", "image-src", "image-full-src", "show-featured-image", "only-show-feature-image", "has-featured-image", "onSelectBack", "onSetImageIconUrl", "onDiscard", "onDiscardFeature", "onSetFeaturedImageIconUrl"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}