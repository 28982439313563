import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "div-convert-demo-event" }
const _hoisted_2 = { class: "div-text-area" }
const _hoisted_3 = {
  key: 0,
  class: "div-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.localViewModel.Text), 1)
    ]),
    (_ctx.localViewModel.ButtonText !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-convert",
            "data-dismiss": "modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.convertEvent()))
          }, _toDisplayString(_ctx.localViewModel.ButtonText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}