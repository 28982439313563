import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, vModelRadio as _vModelRadio, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "parent-div-convert-demo-event"
}
const _hoisted_2 = {
  key: 3,
  class: "parent-div-convert-demo-event"
}
const _hoisted_3 = {
  key: 0,
  class: "parent-div-create-demo-event"
}
const _hoisted_4 = { class: "sticky-div" }
const _hoisted_5 = {
  key: 0,
  class: "div-validation"
}
const _hoisted_6 = { class: "parent-div-basic-information" }
const _hoisted_7 = { class: "banner-div" }
const _hoisted_8 = { class: "visibility-align margin-top-0 primary-heading" }
const _hoisted_9 = {
  id: "logo-div",
  class: "div-image"
}
const _hoisted_10 = ["src", "title"]
const _hoisted_11 = { class: "margin-top-0" }
const _hoisted_12 = { class: "color-red" }
const _hoisted_13 = { class: "div-textarea" }
const _hoisted_14 = { class: "department div-department" }
const _hoisted_15 = { class: "position-relative" }
const _hoisted_16 = { class: "margin-top-0" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { value: "0" }
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "parent-div-quotawarning" }
const _hoisted_21 = { class: "add-description custom-warning" }
const _hoisted_22 = { id: "textarea-div-description" }
const _hoisted_23 = { class: "display-flex" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "lbl-department-quota" }
const _hoisted_26 = { class: "parent-div-description" }
const _hoisted_27 = { class: "add-description margin-left-4" }
const _hoisted_28 = { class: "margin-top-0" }
const _hoisted_29 = { id: "textAreaEventDescription" }
const _hoisted_30 = ["maxlength"]
const _hoisted_31 = {
  key: 1,
  class: "display-flex"
}
const _hoisted_32 = {
  key: 2,
  class: "parent-div-dates"
}
const _hoisted_33 = { class: "banner-div position-relative" }
const _hoisted_34 = { class: "visibility-align primary-heading" }
const _hoisted_35 = { class: "color-red" }
const _hoisted_36 = { class: "width85 assign-date" }
const _hoisted_37 = {
  key: 3,
  class: "div-event-date"
}
const _hoisted_38 = { class: "banner-div" }
const _hoisted_39 = {
  key: 0,
  class: "visibility-align margin-top-0 primary-heading"
}
const _hoisted_40 = { class: "color-red" }
const _hoisted_41 = {
  id: "duration-date",
  class: "duration-date"
}
const _hoisted_42 = { class: "date-position" }
const _hoisted_43 = { class: "margin-top-0" }
const _hoisted_44 = {
  id: "duration-date",
  class: "duration-date"
}
const _hoisted_45 = { class: "date-position" }
const _hoisted_46 = { class: "margin-top-0" }
const _hoisted_47 = { class: "parent-div-timezone" }
const _hoisted_48 = { class: "div-timezone position-relative" }
const _hoisted_49 = { class: "margin-top-0" }
const _hoisted_50 = { class: "parent-div-eventvisbility" }
const _hoisted_51 = { class: "banner-div position-relative" }
const _hoisted_52 = { class: "right-justify-content-event-visibility-text div-event-visibility-text margin-top-0 primary-heading" }
const _hoisted_53 = {
  key: 0,
  class: "parent-div-event-visibility width80"
}
const _hoisted_54 = ["id"]
const _hoisted_55 = { class: "parent-div-generatecode" }
const _hoisted_56 = { class: "div-code-block" }
const _hoisted_57 = { class: "div-generatecode" }
const _hoisted_58 = { class: "lbl-event-code" }
const _hoisted_59 = { class: "color-red" }
const _hoisted_60 = {
  id: "divEventCode",
  class: "div-event-code"
}
const _hoisted_61 = {
  id: "divGenerateCode",
  class: "div-generate-code"
}
const _hoisted_62 = ["title"]
const _hoisted_63 = {
  key: 0,
  class: "div-inline-loader"
}
const _hoisted_64 = { class: "ajax-loader" }
const _hoisted_65 = ["src"]
const _hoisted_66 = { class: "max-content margin-top-negative-10" }
const _hoisted_67 = {
  key: 0,
  id: "lblIncludeCodeInLink",
  class: "margin-bottom-0"
}
const _hoisted_68 = ["disabled"]
const _hoisted_69 = {
  key: 1,
  class: "font-weight-bold margin-left-20"
}
const _hoisted_70 = { class: "parent-div-event-access-link" }
const _hoisted_71 = {
  key: 0,
  class: "div-event-access-link"
}
const _hoisted_72 = {
  id: "divEventAccessLink",
  class: "div-input-event-access-link"
}
const _hoisted_73 = ["value"]
const _hoisted_74 = ["title"]
const _hoisted_75 = ["src"]
const _hoisted_76 = ["title"]
const _hoisted_77 = ["src"]
const _hoisted_78 = {
  key: 1,
  class: "margin-top-5"
}
const _hoisted_79 = { class: "margin-bottom-10" }
const _hoisted_80 = {
  for: "mobileandpwa",
  class: "margin-left-10 margin-bottom-0 description"
}
const _hoisted_81 = {
  for: "mobileonly",
  class: "margin-left-10 description"
}
const _hoisted_82 = { class: "margin-top-2" }
const _hoisted_83 = ["href"]
const _hoisted_84 = { key: 2 }
const _hoisted_85 = { key: 3 }
const _hoisted_86 = {
  key: 0,
  class: "alert alert-success show-copied-message"
}
const _hoisted_87 = {
  key: 4,
  class: "parent-div-generatecode"
}
const _hoisted_88 = { class: "div-code-block regenrated-message" }
const _hoisted_89 = { class: "parent-div-location" }
const _hoisted_90 = { class: "banner-div div-location" }
const _hoisted_91 = { class: "right-justify-content margin-top-0 primary-heading" }
const _hoisted_92 = { class: "div-location-radio-buttons" }
const _hoisted_93 = ["title"]
const _hoisted_94 = ["checked"]
const _hoisted_95 = ["title"]
const _hoisted_96 = ["checked"]
const _hoisted_97 = ["title"]
const _hoisted_98 = ["checked"]
const _hoisted_99 = {
  key: 0,
  id: "lblUnspecified",
  class: "lbl-venue-location margin-top-0"
}
const _hoisted_100 = ["checked"]
const _hoisted_101 = {
  key: 5,
  class: "parent-div-address"
}
const _hoisted_102 = { class: "width100 div-event-visibility" }
const _hoisted_103 = {
  key: 6,
  class: "display-flex"
}
const _hoisted_104 = {
  key: 7,
  class: "div-advertisement display-flex"
}
const _hoisted_105 = {
  key: 8,
  class: "display-flex"
}
const _hoisted_106 = {
  key: 9,
  class: "parent-div-demoevent"
}
const _hoisted_107 = { class: "banner-div" }
const _hoisted_108 = { class: "right-justify-content margin-top-0" }
const _hoisted_109 = { class: "position-relative div-location-radio-buttons" }
const _hoisted_110 = {
  key: 0,
  class: "margin-top-0"
}
const _hoisted_111 = ["disabled"]
const _hoisted_112 = {
  key: 10,
  class: "display-flex"
}
const _hoisted_113 = {
  key: 11,
  class: "parent-div-superadminsetting"
}
const _hoisted_114 = { class: "banner-div position-relative" }
const _hoisted_115 = { class: "right-justify-content margin-right-lbl-super-admin-setting margin-top-0 primary-heading" }
const _hoisted_116 = { class: "div-location-radio-buttons" }
const _hoisted_117 = ["disabled"]
const _hoisted_118 = { id: "divHideEventExceptSuperAdmins" }
const _hoisted_119 = {
  id: "lblHideEventExceptSuperAdmins",
  class: "margin-top-0 description"
}
const _hoisted_120 = { class: "display-block" }
const _hoisted_121 = {
  key: 0,
  id: "lblOverrideParticipantLimit",
  class: "margin-top-0 description"
}
const _hoisted_122 = ["disabled"]
const _hoisted_123 = { id: "dvEnableStripeTestMode" }
const _hoisted_124 = {
  id: "lblEnableStripeTestMode",
  class: "margin-top-0 description"
}
const _hoisted_125 = {
  key: 12,
  class: "display-flex"
}
const _hoisted_126 = {
  key: 13,
  class: "div-self-checkin"
}
const _hoisted_127 = { class: "footer-tab" }
const _hoisted_128 = { class: "parent-div-buttons" }
const _hoisted_129 = ["disabled"]
const _hoisted_130 = ["disabled"]
const _hoisted_131 = { key: 0 }
const _hoisted_132 = { key: 1 }
const _hoisted_133 = {
  key: 5,
  class: "event-setting-addicon"
}
const _hoisted_134 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRCodePopUp = _resolveComponent("QRCodePopUp")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_WarningMessageWithButton = _resolveComponent("WarningMessageWithButton")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_Card = _resolveComponent("Card")!
  const _component_WarningMessageBar = _resolveComponent("WarningMessageBar")!
  const _component_MapPicker = _resolveComponent("MapPicker")!
  const _component_Advertisement = _resolveComponent("Advertisement")!
  const _component_SelfCheckIn = _resolveComponent("SelfCheckIn")!
  const _component_ParticipantTicketAndQR = _resolveComponent("ParticipantTicketAndQR")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", {
    id: "eventDetail",
    class: _normalizeClass(["event-detail", {'border-radius-20': _ctx.localEventDetailViewModal.isMobileView }]),
    onKeypress: _cache[45] || (_cache[45] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onClick: _cache[46] || (_cache[46] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onMousemove: _cache[47] || (_cache[47] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onTouchstart: _cache[48] || (_cache[48] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
  }, [
    (_ctx.localEventDetailViewModal.showQrCodeModalPopup)
      ? (_openBlock(), _createBlock(_component_QRCodePopUp, {
          key: 0,
          "show-modal-popup": _ctx.localEventDetailViewModal.showQrCodeModalPopup,
          "header-content": _ctx.localEventDetailViewModal.headerContent,
          "image-url": _ctx.localEventDetailViewModal.qrCodeURL,
          "button-text": _ctx.localEventDetailViewModal.buttonText,
          "note-text": _ctx.localEventDetailViewModal.noteText,
          "file-name": _ctx.localEventDetailViewModal.fileName,
          onClose: _ctx.CloseQRCodePopup
        }, null, 8, ["show-modal-popup", "header-content", "image-url", "button-text", "note-text", "file-name", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.localEventDetailViewModal.showModalPopup)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 1,
          "modal-pop-up": _ctx.localEventDetailViewModal.convertEventModelPopup,
          onClose: _ctx.CloseModelPopup,
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveModelPopUp($event)))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.CheckEventIsDemoEvent())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_WarningMessageWithButton, {
            WarningMessageViweModel: _ctx.localEventDetailViewModal.demoEventWarningMessage,
            onConvertEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ConvertDemoEvent()))
          }, null, 8, ["WarningMessageViweModel"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.localEventDetailViewModal.isFreezeEvent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_WarningMessageWithButton, {
            WarningMessageViweModel: _ctx.localEventDetailViewModal.freezeEventWarningMessage,
            onConvertEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ConvertFreezeEvent()))
          }, null, 8, ["WarningMessageViweModel"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          id: "notificationEditMode",
          class: _normalizeClass(["div-notification-edit-mode", { 'disabledpage': _ctx.localEventDetailViewModal.isFreezeEvent }])
        }, [
          (_ctx.localEventDetailViewModal.isCreateDemoOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_WarningMessageWithButton, {
                  WarningMessageViweModel: _ctx.localEventDetailViewModal.createDemoEventOnly,
                  onConvertEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ConvertDemoEvent()))
                }, null, 8, ["WarningMessageViweModel"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.localEventDetailViewModal.isVisibleValidationSummary)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_ValidationSummary, {
                    "error-fields": _ctx.localEventDetailViewModal.validationErrorFields,
                    onCloseClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                  }, null, 8, ["error-fields"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("EventSetting.BasicInformation")), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: "img-select",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ClickOnImage(true)))
                }, [
                  _createElementVNode("img", {
                    class: "iconImg round-image",
                    src: _ctx.GetImageUrl(),
                    title: _ctx.GetImageIconToolTipMessage()
                  }, null, 8, _hoisted_10)
                ])
              ]),
              _createElementVNode("div", {
                id: "content-div",
                class: _normalizeClass([_ctx.ShowDepartmentDropdown() ? 'content-div' : _ctx.localEventDetailViewModal.isMobileView ? 'width100' : 'width70'])
              }, [
                _createElementVNode("label", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.EventName")), 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Asteric")), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.ValidationViewModel.Name.$model) = $event)),
                    class: _normalizeClass(["form-control", _ctx.status(_ctx.v$.ValidationViewModel.Name)]),
                    type: "text",
                    maxlength: "120"
                  }, null, 2), [
                    [_vModelText, _ctx.v$.ValidationViewModel.Name.$model]
                  ])
                ])
              ], 2),
              _withDirectives(_createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.Department")) + " ", 1),
                    _createElementVNode("i", {
                      class: "fa fa-info-circle timeZonetooltip display-none",
                      "aria-hidden": "true",
                      onMouseover: _cache[7] || (_cache[7] = ($event: any) => (_ctx.GetInformationString(true, 'department'))),
                      onMouseleave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.GetInformationString(false, 'department')))
                    }, null, 32)
                  ]),
                  _withDirectives(_createElementVNode("div", { class: "department-information tooltip-prop" }, _toDisplayString(_ctx.$t("EventSetting.ToolTip.Department")), 513), [
                    [_vShow, _ctx.localEventDetailViewModal.isDepartmentTooltipInfoVisible]
                  ]),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("select", {
                      id: "selDepartment",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localEventDetailViewModal.applicationInstanceModel.DepartmentId) = $event)),
                      disabled: _ctx.DisabledDepartment() || _ctx.IsLoggedInUserDepartmentAdmin(),
                      class: "select-dropdown form-control sel-department selectBox",
                      onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.OnDepartmentChange($event)))
                    }, [
                      _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t("EventSetting.Unspecified")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEventDetailViewModal.Departments, (department) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: department.Id,
                          value: department.Id
                        }, _toDisplayString(department.SelectedText), 9, _hoisted_19))
                      }), 128))
                    ], 40, _hoisted_17), [
                      [_vModelSelect, _ctx.localEventDetailViewModal.applicationInstanceModel.DepartmentId]
                    ])
                  ])
                ])
              ], 512), [
                [_vShow, _ctx.ShowDepartmentDropdown()]
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_20, [
              _cache[49] || (_cache[49] = _createElementVNode("div", { class: "banner-div" }, null, -1)),
              _cache[50] || (_cache[50] = _createElementVNode("div", { class: "logo-div" }, null, -1)),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("img", {
                      src: require('@/assets/Images/warning-sign-svgrepo-com.svg'),
                      class: "width5 padding-5 margin-right-5",
                      alt: "missing"
                    }, null, 8, _hoisted_24),
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("EventSetting.DepartmentQuota")), 1)
                  ])
                ])
              ])
            ], 512), [
              [_vShow, _ctx.localEventDetailViewModal.showDescriptionWarning]
            ]),
            _createElementVNode("div", _hoisted_26, [
              _cache[51] || (_cache[51] = _createElementVNode("div", { class: "banner-div" }, null, -1)),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("EventSetting.WelcomeText")), 1),
                _createElementVNode("div", _hoisted_29, [
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((
                  _ctx.localEventDetailViewModal.applicationInstanceModel.Description
                ) = $event)),
                    class: "form-control textarea-title-description textarea-size",
                    maxlength: _ctx.localEventDetailViewModal.descriptionLengthAllowed
                  }, null, 8, _hoisted_30), [
                    [_vModelText, 
                  _ctx.localEventDetailViewModal.applicationInstanceModel.Description
                ]
                  ])
                ])
              ])
            ]),
            (_ctx.ShowEventDate() || _ctx.IsAssignDateVisble())
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[52] || (_cache[52] = [
                  _createElementVNode("div", { class: "width100 custom-hr" }, [
                    _createElementVNode("hr", { class: "margin-40-0-35" })
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.IsAssignDateVisble())
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("label", _hoisted_34, [
                      _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.EventDate")) + " ", 1),
                      _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("Asteric")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible) = $event)),
                        type: "checkbox",
                        class: "margin-right-6",
                        onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.ChangeAssignEventDate()))
                      }, null, 544), [
                        [_vModelCheckbox, _ctx.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible]
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.AssignEventDate")), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.ShowEventDate())
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    (!_ctx.IsAssignDateVisble())
                      ? (_openBlock(), _createElementBlock("label", _hoisted_39, [
                          _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.EventDate")) + " ", 1),
                          _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("Asteric")), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t("Startdate")), 1),
                      _createVNode(_component_Datepicker, {
                        date: _ctx.GetStartDate(),
                        "full-day-time-format": _ctx.CheckTimeFormatStartDate(),
                        "date-only": true,
                        "id-value": _ctx.localEventDetailViewModal.startDateIdString,
                        onSelectedDate: _ctx.SetBrowserDateValue
                      }, null, 8, ["date", "full-day-time-format", "id-value", "onSelectedDate"])
                    ])
                  ], 512), [
                    [_vShow, _ctx.localEventDetailViewModal.showDates]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t("EndDate")), 1),
                      _createVNode(_component_Datepicker, {
                        date: _ctx.GetEndDate(),
                        "full-day-time-format": _ctx.CheckTimeFormatEndDate(),
                        "date-only": true,
                        "id-value": _ctx.localEventDetailViewModal.endDateIdString,
                        mindatetime: _ctx.localEventDetailViewModal.minDateTime,
                        maxdatetime: _ctx.localEventDetailViewModal.maxDateTime,
                        onSelectedDate: _ctx.SetBrowserDateValueEnd
                      }, null, 8, ["date", "full-day-time-format", "id-value", "mindatetime", "maxdatetime", "onSelectedDate"])
                    ])
                  ], 512), [
                    [_vShow, _ctx.localEventDetailViewModal.showDates]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("label", _hoisted_49, [
                        _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.TimeZone")) + " ", 1),
                        _createElementVNode("i", {
                          class: "fa fa-info-circle timeZonetooltip display-none",
                          "aria-hidden": "true",
                          onMouseover: _cache[14] || (_cache[14] = ($event: any) => (_ctx.GetInformationString(true, 'timeZone'))),
                          onMouseleave: _cache[15] || (_cache[15] = ($event: any) => (_ctx.GetInformationString(false, 'timeZone')))
                        }, null, 32)
                      ]),
                      _withDirectives(_createElementVNode("div", { class: "timezone-information tooltip-prop" }, _toDisplayString(_ctx.$t("EventSetting.TimeZoneToolTip")), 513), [
                        [_vShow, _ctx.localEventDetailViewModal.isTimezoneTooltipInfoVisible]
                      ]),
                      _createVNode(_component_v_select, {
                        modelValue: _ctx.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone) = $event)),
                        options: _ctx.localEventDetailViewModal.TimeZones,
                        reduce: TimeZone => TimeZone.Id,
                        label: "DisplayName",
                        index: "Id",
                        onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.OnTimeZoneChange($event)))
                      }, null, 8, ["modelValue", "options", "reduce"])
                    ])
                  ], 512), [
                    [_vShow, _ctx.localEventDetailViewModal.showDates]
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[62] || (_cache[62] = _createElementVNode("div", { class: "display-flex" }, [
              _createElementVNode("div", { class: "width100 custom-hr" }, [
                _createElementVNode("hr", { class: "margin-40-0" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.$t("EventSetting.Eventvisibility")), 1)
              ]),
              (_ctx.showEventVsibilityCards)
                ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEventDetailViewModal.localEventVisibilityCards, (localEventVisibilityCard) => {
                      return (_openBlock(), _createElementBlock("div", {
                        id: 'divEventVisibility'+localEventVisibilityCard.Id,
                        key: localEventVisibilityCard.Id,
                        class: _normalizeClass(["div-event-visibility-card cursor-pointer", { 'blank-card': !localEventVisibilityCard.IsVisible }])
                      }, [
                        _createVNode(_component_Card, {
                          "event-visibility-card": localEventVisibilityCard,
                          onSelectedCardValue: _ctx.ChangeEventType
                        }, null, 8, ["event-visibility-card", "onSelectedCardValue"])
                      ], 10, _hoisted_54))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_55, [
              _cache[53] || (_cache[53] = _createElementVNode("div", { class: "banner-div" }, null, -1)),
              _createElementVNode("div", _hoisted_56, [
                _withDirectives(_createElementVNode("div", _hoisted_57, [
                  _createElementVNode("label", _hoisted_58, [
                    _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.EventCode")) + " ", 1),
                    _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("Asteric")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_60, [
                    _withDirectives(_createElementVNode("input", {
                      id: "inputEventCode",
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.v$.ValidationViewModel.ApplicationCode.$model) = $event)),
                      class: _normalizeClass(["form-control event-code-text", _ctx.status(_ctx.v$.ValidationViewModel.ApplicationCode)]),
                      maxlength: "16",
                      onInput: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.HandleInputOnKeyupApplicationCodeType && _ctx.HandleInputOnKeyupApplicationCodeType(...args)))
                    }, null, 34), [
                      [_vModelText, _ctx.v$.ValidationViewModel.ApplicationCode.$model]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("label", {
                      id: "lblGenerateCode",
                      class: "genrate-code",
                      title: _ctx.IconToolTip(_ctx.$t('EventSetting.ToolTip.GenerateCode')),
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.GenrateCode()))
                    }, _toDisplayString(_ctx.$t("EventSetting.GenrateEventCode")), 9, _hoisted_62),
                    (_ctx.localEventDetailViewModal.showInlineLoader)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                          _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("img", {
                              class: "img-responsive ajax-loader-img",
                              src: _ctx.loadingImage,
                              alt: "Wait"
                            }, null, 8, _hoisted_65)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    (_ctx.localEventDetailViewModal.isGenerateCode && _ctx.reqInfo.ApplicationInstanceId > 0)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_67, [
                          _withDirectives(_createElementVNode("input", {
                            id: "inputIncludeCodeInLink",
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((
                      _ctx.localEventDetailViewModal.applicationInstanceModel
                        .IncludeEventCodeInLink
                    ) = $event)),
                            type: "checkbox",
                            class: "margin-right-6",
                            disabled: _ctx.isEnableIncludeEventCode,
                            onChange: _cache[22] || (_cache[22] = ($event: any) => (_ctx.IncludeEventCodeInLink()))
                          }, null, 40, _hoisted_68), [
                            [_vModelCheckbox, 
                      _ctx.localEventDetailViewModal.applicationInstanceModel
                        .IncludeEventCodeInLink
                    ]
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.IncludeCodeInTheLink")), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.reqInfo.ApplicationInstanceId > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_69, _toDisplayString(_ctx.$t("DynamicTextInParentheses", {text: _ctx.$t('Recommended')})), 1))
                      : _createCommentVNode("", true)
                  ])
                ], 512), [
                  [_vShow, _ctx.localEventDetailViewModal.isGenerateCode]
                ]),
                _createElementVNode("div", _hoisted_70, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventSetting.EventAccessLink")), 1),
                  (_ctx.IsNewEvent())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                        _createElementVNode("div", _hoisted_72, [
                          _createElementVNode("input", {
                            id: "inputEventAccessLink",
                            class: "form-control",
                            readonly: "",
                            value: _ctx.SetEventAccessLink()
                          }, null, 8, _hoisted_73)
                        ]),
                        _createElementVNode("div", {
                          id: "divQRCodeEventAcessLink",
                          class: "div-icon-copied-icon cursor-pointer",
                          title: _ctx.$t('EventSetting.ToolTip.QRCode'),
                          onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.OpenQRCodeImageInPopup('')))
                        }, [
                          _createElementVNode("img", {
                            id: "imgQRCodeEventAcessLink",
                            class: "copied-icon",
                            src: _ctx.localEventDetailViewModal.qrCodeIcon
                          }, null, 8, _hoisted_75)
                        ], 8, _hoisted_74),
                        _createElementVNode("div", {
                          id: "divCopyCodeEventAcessLink",
                          class: "div-icon-copied-icon cursor-pointer",
                          title: _ctx.$t('CopyLink'),
                          onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.CopyPublicLinkToClipboard()))
                        }, [
                          _createElementVNode("img", {
                            id: "imgCopyCodeEventAcessLink",
                            class: "copied-icon",
                            src: _ctx.localEventDetailViewModal.copiedIcon
                          }, null, 8, _hoisted_77)
                        ], 8, _hoisted_76)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.reqInfo.ApplicationInstanceId > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                        _createElementVNode("div", _hoisted_79, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventSetting.OpenLinkIn")), 1)
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass(["inline-flex margin-top-negative-10 open-link-radio-btn-container", {'flex-direction-column': _ctx.localEventDetailViewModal.isMobileView}])
                        }, [
                          _createElementVNode("div", null, [
                            _withDirectives(_createElementVNode("input", {
                              id: "mobileandpwa",
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp) = $event)),
                              type: "radio",
                              name: "eventaccess",
                              value: false
                            }, null, 512), [
                              [_vModelRadio, _ctx.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp]
                            ]),
                            _createElementVNode("label", _hoisted_80, _toDisplayString(_ctx.$t("EventSetting.MobileAppOrMobileBrowser")), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _withDirectives(_createElementVNode("input", {
                              id: "mobileonly",
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp) = $event)),
                              type: "radio",
                              name: "eventaccess",
                              class: "mobile-only-radio-btn",
                              value: true
                            }, null, 512), [
                              [_vModelRadio, _ctx.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp]
                            ]),
                            _createElementVNode("label", _hoisted_81, _toDisplayString(_ctx.$t("EventSetting.MobileAppOnly")), 1)
                          ])
                        ], 2),
                        _createElementVNode("div", _hoisted_82, [
                          _createElementVNode("a", {
                            href: _ctx.downloadAccessInstructionLink,
                            target: "_blank",
                            class: "hyperLink"
                          }, _toDisplayString(_ctx.$t("EventSetting.DownloadAccessInstructions")), 9, _hoisted_83)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (
                  _ctx.localEventDetailViewModal.showEventAccessLinkWarningMessageBar
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                        _createVNode(_component_WarningMessageBar, {
                          "information-view-model": 
                    _ctx.localEventDetailViewModal.eventAccessLinkWarningMessage
                  
                        }, null, 8, ["information-view-model"])
                      ]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.localEventDetailViewModal.isGenerateCode &&
                    _ctx.localEventDetailViewModal.showWarningMessageBar
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                        _createVNode(_component_WarningMessageBar, {
                          "information-view-model": 
                    _ctx.localEventDetailViewModal.accessLinkInformationMessage
                  ,
                          onInformationMessageBarCloseOperation: _cache[27] || (_cache[27] = ($event: any) => (
                    _ctx.WarningMessageBarCloseOperation()
                  ))
                        }, null, 8, ["information-view-model"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (_ctx.localEventDetailViewModal.showCopiedMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                      _cache[54] || (_cache[54] = _createElementVNode("i", {
                        class: "fa fa-check",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.ShowEventSettingRegenratedMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                  _cache[55] || (_cache[55] = _createElementVNode("div", { class: "banner-div" }, null, -1)),
                  _createElementVNode("div", _hoisted_88, [
                    _createVNode(_component_WarningMessageBar, {
                      "information-view-model": 
                _ctx.localEventDetailViewModal.eventAccessLinkWarningMessage
              
                    }, null, 8, ["information-view-model"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[63] || (_cache[63] = _createElementVNode("div", { class: "display-flex" }, [
              _createElementVNode("div", { class: "width100 custom-hr" }, [
                _createElementVNode("hr", { class: "margin-35-0" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_89, [
              _createElementVNode("div", _hoisted_90, [
                _createElementVNode("label", _hoisted_91, _toDisplayString(_ctx.$t("EventSetting.Location")), 1)
              ]),
              _createElementVNode("div", _hoisted_92, [
                _createElementVNode("label", {
                  id: "lblPhysicalVenue",
                  title: _ctx.IconToolTip(_ctx.$t('EventSetting.ToolTip.PhysicalVenue')),
                  class: "lbl-physical-venue-location margin-top-0 description"
                }, [
                  _createElementVNode("input", {
                    id: "inputPhysicalVenue",
                    type: "radio",
                    name: "locationtype",
                    value: "Physicalvenue",
                    class: "margin-right-6",
                    checked: _ctx.GetLocationTypeSelected(1),
                    onChange: _cache[28] || (_cache[28] = ($event: any) => (_ctx.ChangeLocationType(1)))
                  }, null, 40, _hoisted_94),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.Physicalvenue")), 1)
                ], 8, _hoisted_93),
                _createElementVNode("label", {
                  id: "lblOnlineVenue",
                  title: _ctx.IconToolTip(_ctx.$t('EventSetting.ToolTip.OnlineVenue')),
                  class: "lbl-venue-location margin-top-0 description"
                }, [
                  _createElementVNode("input", {
                    id: "inputOnlineVenue",
                    type: "radio",
                    name: "locationtype",
                    value: "VirtualOnline",
                    class: "margin-right-6",
                    checked: _ctx.GetLocationTypeSelected(2),
                    onChange: _cache[29] || (_cache[29] = ($event: any) => (_ctx.ChangeLocationType(2)))
                  }, null, 40, _hoisted_96),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.VirtualOnline")), 1)
                ], 8, _hoisted_95),
                _createElementVNode("label", {
                  id: "lblHybridVenue",
                  class: "lbl-venue-location margin-top-0 description",
                  title: _ctx.IconToolTip(_ctx.$t('EventSetting.ToolTip.HybridVenue'))
                }, [
                  _createElementVNode("input", {
                    id: "inputHybridVenue",
                    type: "radio",
                    name: "locationtype",
                    value: "Hybrid",
                    class: "margin-right-6",
                    checked: _ctx.GetLocationTypeSelected(3),
                    onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.ChangeLocationType(3)))
                  }, null, 40, _hoisted_98),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.Hybrid")), 1)
                ], 8, _hoisted_97),
                (_ctx.IsUnspecifiedLocationOptionVisible())
                  ? (_openBlock(), _createElementBlock("label", _hoisted_99, [
                      _createElementVNode("input", {
                        id: "inputUnspecified",
                        type: "radio",
                        name: "locationtype",
                        value: "Unspecified",
                        class: "margin-right-6",
                        checked: _ctx.GetLocationTypeSelected(0),
                        onChange: _cache[31] || (_cache[31] = ($event: any) => (_ctx.ChangeLocationType(0)))
                      }, null, 40, _hoisted_100),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.Unspecified")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.localEventDetailViewModal.showMap)
              ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                  _cache[56] || (_cache[56] = _createElementVNode("div", { class: "banner-div" }, null, -1)),
                  _createElementVNode("div", _hoisted_102, [
                    _createVNode(_component_MapPicker, {
                      "selected-item": _ctx.localEventDetailViewModal.applicationInstanceModel,
                      "show-heading": false
                    }, null, 8, ["selected-item"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.IsNewEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_103, _cache[57] || (_cache[57] = [
                  _createElementVNode("div", { class: "width100 custom-hr" }, [
                    _createElementVNode("hr", { class: "margin-35-0" })
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.IsNewEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                  _createVNode(_component_Advertisement, {
                    advertisementViewModel: _ctx.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel,
                    onShowimageprocess: _cache[32] || (_cache[32] = ($event: any) => (_ctx.ShowImageProcess($event))),
                    onChangeObject: _cache[33] || (_cache[33] = ($event: any) => (_ctx.IsDirtyFlag()))
                  }, null, 8, ["advertisementViewModel"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.ShowCreateDemoEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_105, _cache[58] || (_cache[58] = [
                  _createElementVNode("div", { class: "width100 custom-hr" }, [
                    _createElementVNode("hr")
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.ShowCreateDemoEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_106, [
                  _createElementVNode("div", _hoisted_107, [
                    _createElementVNode("label", _hoisted_108, _toDisplayString(_ctx.$t("EventSetting.DemoEvent")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_109, [
                    true
                      ? (_openBlock(), _createElementBlock("label", _hoisted_110, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((
                  _ctx.localEventDetailViewModal.applicationInstanceModel.IsDemoEvent
                ) = $event)),
                            disabled: _ctx.localEventDetailViewModal.isCreateDemoOnly,
                            type: "checkbox",
                            class: "margin-right-6"
                          }, null, 8, _hoisted_111), [
                            [_vModelCheckbox, 
                  _ctx.localEventDetailViewModal.applicationInstanceModel.IsDemoEvent
                ]
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("EventSetting.DemoEventDescription")), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.IsLoggedInUserSuperAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_112, _cache[59] || (_cache[59] = [
                  _createElementVNode("div", { class: "width100 custom-hr" }, [
                    _createElementVNode("hr", { class: "margin-35-0" })
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.IsLoggedInUserSuperAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_113, [
                  _createElementVNode("div", _hoisted_114, [
                    _createElementVNode("label", _hoisted_115, _toDisplayString(_ctx.$t("EventSetting.SuperAdminSetting")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_116, [
                    _createElementVNode("div", {
                      class: "inline-flex100",
                      onClick: _cache[36] || (_cache[36] = 
//@ts-ignore
(...args) => (_ctx.changeSuperAdminHidddenEvent && _ctx.changeSuperAdminHidddenEvent(...args)))
                    }, [
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          id: "inputHideEventExceptSuperAdmins",
                          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((
                    _ctx.localEventDetailViewModal.applicationInstanceModel
                      .IsSuperAdminHiddenEvent
                  ) = $event)),
                          disabled: 
                    _ctx.localEventDetailViewModal.applicationInstanceModel.Id > 0
                  ,
                          type: "checkbox",
                          class: "margin-right-6"
                        }, null, 8, _hoisted_117), [
                          [_vModelCheckbox, 
                    _ctx.localEventDetailViewModal.applicationInstanceModel
                      .IsSuperAdminHiddenEvent
                  ]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_118, [
                        _createElementVNode("label", _hoisted_119, _toDisplayString(_ctx.$t("EventSetting.HideEventText")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_120, [
                      (_ctx.ShowOverrideParticipantLimit())
                        ? (_openBlock(), _createElementBlock("label", _hoisted_121, [
                            _withDirectives(_createElementVNode("input", {
                              id: "lblOverrideParticipantLimit",
                              "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((
                    _ctx.localEventDetailViewModal.applicationInstanceModel
                      .OverrideParticipantLimit
                  ) = $event)),
                              type: "checkbox",
                              class: "margin-right-6 description"
                            }, null, 512), [
                              [_vModelCheckbox, 
                    _ctx.localEventDetailViewModal.applicationInstanceModel
                      .OverrideParticipantLimit
                  ]
                            ]),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("EventSetting.OverrideParticipantLimit")), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.IsTestStripeModeCheckVisible())
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "inline-flex100",
                          onClick: _cache[39] || (_cache[39] = 
//@ts-ignore
(...args) => (_ctx.ToggleStripeTestMode && _ctx.ToggleStripeTestMode(...args)))
                        }, [
                          _createElementVNode("div", null, [
                            _withDirectives(_createElementVNode("input", {
                              id: "chkEnableStripeTestMode",
                              "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((
                    _ctx.localEventDetailViewModal.applicationInstanceModel.EnableStripeTestMode
                  ) = $event)),
                              disabled: 
                    _ctx.localEventDetailViewModal.applicationInstanceModel.Id > 0
                  ,
                              type: "checkbox",
                              class: "margin-right-6 description"
                            }, null, 8, _hoisted_122), [
                              [_vModelCheckbox, 
                    _ctx.localEventDetailViewModal.applicationInstanceModel.EnableStripeTestMode
                  ]
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_123, [
                            _createElementVNode("label", _hoisted_124, _toDisplayString(_ctx.$t("EventSetting.EnableStripeTestMode")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.IsNewEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_125, _cache[60] || (_cache[60] = [
                  _createElementVNode("div", { class: "width100 custom-hr" }, [
                    _createElementVNode("hr", { class: "margin-35-0" })
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.IsNewEvent())
              ? (_openBlock(), _createElementBlock("div", _hoisted_126, [
                  _createVNode(_component_SelfCheckIn, {
                    "checkedself-check-in-check-box": 
              _ctx.localEventDetailViewModal.applicationInstanceModel.EnableParticipantSelfCheckIn
            ,
                    eventAccessLinkQRCodeUrl: _ctx.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl,
                    onChangevaluecheckin: _cache[40] || (_cache[40] = ($event: any) => (_ctx.ChangeValueCheckIn($event))),
                    onOpenimageinppup: _ctx.GetAndOpenQRCodeImageInPopup
                  }, null, 8, ["checkedself-check-in-check-box", "eventAccessLinkQRCodeUrl", "onOpenimageinppup"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _cache[61] || (_cache[61] = _createElementVNode("div", { class: "display-flex width100" }, [
                _createElementVNode("div", { class: "width100 custom-hr" }, [
                  _createElementVNode("hr", { class: "margin-35-0" })
                ])
              ], -1)),
              _createVNode(_component_ParticipantTicketAndQR, {
                IsParticipantQRVisible: _ctx.localEventDetailViewModal.applicationInstanceModel.IsParticipantQRVisible,
                onChangeParticipantQrValue: _cache[41] || (_cache[41] = ($event: any) => (_ctx.ChangeParticipantQRValue($event)))
              }, null, 8, ["IsParticipantQRVisible"])
            ])
          ]),
          _createElementVNode("div", _hoisted_127, [
            _cache[64] || (_cache[64] = _createElementVNode("div", { class: "display-flex" }, [
              _createElementVNode("div", { class: "width100" }, [
                _createElementVNode("hr", { class: "footer-hr margin-top-0" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_128, [
              _createElementVNode("div", null, [
                (!_ctx.IsNewEvent())
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      id: "btnCancel",
                      class: "btn btn-secondary btn-size-default btn-cancelevent cursor-pointer",
                      onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.GoToEventListingPage()))
                    }, _toDisplayString(_ctx.$t("Button.Cancel")), 1))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      id: "btnDiscard",
                      class: "btn btn-secondary btn-size-default btn-cancelevent cursor-pointer",
                      disabled: _ctx.isDirtyObject && _ctx.IsNewEvent(),
                      onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.Discard()))
                    }, _toDisplayString(_ctx.$t("Button.Discard")), 9, _hoisted_129))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  id: "btnSave",
                  class: "btn btn-primary btn-size-default width100 btn-create-event cursor-pointer",
                  disabled: _ctx.isDirtyObject && _ctx.IsNewEvent(),
                  onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.Submit(_ctx.v$)))
                }, [
                  (_ctx.IsNewEvent())
                    ? (_openBlock(), _createElementBlock("span", _hoisted_131, _toDisplayString(_ctx.$t("Button.Save")), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_132, _toDisplayString(_ctx.$t("EventSetting.Createevent")), 1))
                ], 8, _hoisted_130)
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_133, [
          _createVNode(_component_ImageProcess, {
            "featured-image-full-src": _ctx.GetfeaturedimageFullUrl(),
            "featuredimage-src": _ctx.GetFeaturedImageUrl(),
            "featured-image-width": _ctx.localEventDetailViewModal.FeaturedImageWidth,
            "featured-image-height": _ctx.localEventDetailViewModal.FeaturedImageHeight,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageFullUrl(),
            "show-featured-image": _ctx.localEventDetailViewModal.isEnableFeaturedImage,
            "heading-title": _ctx.localEventDetailViewModal.isEnableFeaturedImage == true
          ? _ctx.$t('ImageProcess.headingTitle')
          : _ctx.$t('ImageProcess.iconImage')
        ,
            "has-featured-image": _ctx.CheckFeaturedImage(),
            "left-position": _ctx.localEventDetailViewModal.LeftPosition,
            "top-position": _ctx.localEventDetailViewModal.TopPosition,
            "module-name": _ctx.localEventDetailViewModal.moduleName,
            subModuleName: _ctx.subModuleName,
            "old-selected-font-awesome-icon": _ctx.localEventDetailViewModal.previouslySelectedFontAwesomeIcon,
            "show-title-below-featured-image": _ctx.localEventDetailViewModal.ShowFeatureImageBelowTitle,
            onlyShowFeatureImage: _ctx.localEventDetailViewModal.isEnableFeaturedImage,
            onSetImageIconUrl: _ctx.SetImageIconUrl,
            onSelectBack: _ctx.ClickOnImage,
            onSetFeaturedImageIconUrl: _ctx.SetFeaturedImageIconUrl,
            onShowTitleBelowFeaturedImage: _ctx.ShowProgramtitleBelowFeaturedImage
          }, null, 8, ["featured-image-full-src", "featuredimage-src", "featured-image-width", "featured-image-height", "image-src", "image-full-src", "show-featured-image", "heading-title", "has-featured-image", "left-position", "top-position", "module-name", "subModuleName", "old-selected-font-awesome-icon", "show-title-below-featured-image", "onlyShowFeatureImage", "onSetImageIconUrl", "onSelectBack", "onSetFeaturedImageIconUrl", "onShowTitleBelowFeaturedImage"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.IsNewEvent())
      ? (_openBlock(), _createElementBlock("div", _hoisted_134, [
          _createVNode(_component_loading, {
            "show-loading": _ctx.clickedOnSaved,
            "border-color": _ctx.loaderBorderColor
          }, null, 8, ["show-loading", "border-color"])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}