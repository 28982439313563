import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "advertisement" }
const _hoisted_2 = { class: "parent-div-advertisement" }
const _hoisted_3 = { class: "div-heading" }
const _hoisted_4 = { class: "lbl-self-advertisement primary-heading" }
const _hoisted_5 = { class: "div-content" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "advertisement-list-header"
}
const _hoisted_8 = { class: "width15" }
const _hoisted_9 = { class: "advertisment-label" }
const _hoisted_10 = { class: "advertisment-url" }
const _hoisted_11 = { class: "advertisment-url-label" }
const _hoisted_12 = { class: "advertisment-count display-flex justify-content-center" }
const _hoisted_13 = {
  key: 1,
  class: "advertisement-list-header"
}
const _hoisted_14 = { key: 2 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["src", "title"]
const _hoisted_17 = {
  key: 1,
  class: "advertisment-url"
}
const _hoisted_18 = ["onUpdate:modelValue", "onKeyup"]
const _hoisted_19 = {
  key: 2,
  class: "advertisment-count display-flex justify-content-center"
}
const _hoisted_20 = { class: "advertisment-count-label" }
const _hoisted_21 = {
  key: 3,
  class: "delete-advertisement"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 3,
  class: "advertisement-addnew-button"
}
const _hoisted_25 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("EventSetting.Advertisement")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("label", {
            class: "advertisement-description description",
            innerHTML: _ctx.$t('EventSetting.AdvertisementDescription')
          }, null, 8, _hoisted_6)
        ]),
        (_ctx.CheckListingHaveValues())
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Banner')), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('URL')), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('EventSetting.ClickCount')), 1)
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "width5" }, null, -1))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("EventSetting.NoAdvertisement")), 1)
            ])),
        (_ctx.CheckListingHaveValues())
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAdvertisementViewModel, (advertisement, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["advertisement-list-list", {'margin-bottom-10' : _ctx.localAdvertisementViewModel.length > 1 && _ctx.localAdvertisementViewModel.length !== (index + 1)}])
                }, [
                  (!advertisement.IsDeleted)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "advertisement-image-div width15",
                        onClick: ($event: any) => (_ctx.ShowImageProcess(index))
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["advertisment-image", [_ctx.CheckBannerUrl(advertisement.BannerUrl) ? 'image-background' : 'padding-4']]),
                          src: _ctx.GetBannerImage(advertisement.BannerUrl),
                          title: _ctx.CheckBannerUrl(advertisement.BannerUrl) ? _ctx.$t('AddImage') : _ctx.$t('Button.Edit'),
                          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowHideAddImageLink(true))),
                          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowHideAddImageLink(false)))
                        }, null, 42, _hoisted_16)
                      ], 8, _hoisted_15))
                    : _createCommentVNode("", true),
                  (!advertisement.IsDeleted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": ($event: any) => ((advertisement.PageUrl) = $event),
                          class: _normalizeClass([{'error': advertisement.UrlIsInValid}, "form-control border-radius-4"]),
                          type: "url",
                          onKeyup: ($event: any) => (_ctx.onChange(advertisement.PageUrl,index))
                        }, null, 42, _hoisted_18), [
                          [_vModelText, advertisement.PageUrl]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!advertisement.IsDeleted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createElementVNode("label", _hoisted_20, _toDisplayString(advertisement.ClickCount), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!advertisement.IsDeleted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("label", {
                          class: "advertisment-cross cursor-pointer",
                          onClick: ($event: any) => (_ctx.RemoveAdvertisement(index))
                        }, [
                          _createElementVNode("img", { src: _ctx.crossIconImage }, null, 8, _hoisted_23)
                        ], 8, _hoisted_22)
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.ValidateBannerUrlAdvertisement())
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("img", {
                class: "advertisment-addnew-image",
                src: _ctx.addIconImage,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CreateNewEmptyAdvertisement()))
              }, null, 8, _hoisted_25),
              _createElementVNode("label", {
                class: "advertisement-addnew-label bold-text",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CreateNewEmptyAdvertisement()))
              }, _toDisplayString(_ctx.$t('EventSetting.AddNewAdvertisement')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}