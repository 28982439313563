import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dialogue-themes-tab" }
const _hoisted_3 = { class: "parent-div-themes background-white" }
const _hoisted_4 = { class: "themes-heading position-relative" }
const _hoisted_5 = { class: "primary-heading" }
const _hoisted_6 = { class: "div-dialogue-theme" }
const _hoisted_7 = { class: "margin-bottom-15" }
const _hoisted_8 = { class: "font-weight-normal" }
const _hoisted_9 = { class: "font-style-italic font-weight-normal" }
const _hoisted_10 = { class: "div-theme-block border-radius-10" }
const _hoisted_11 = {
  key: 0,
  class: "theme-block"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "div-theme-name" }
const _hoisted_14 = { class: "font-size-12 font-weight-normal" }
const _hoisted_15 = { class: "div-input-selected-theme" }
const _hoisted_16 = ["checked"]
const _hoisted_17 = {
  id: "lblCheckBox",
  class: "lbl-default-theme-description description"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  id: "lblCheckBox",
  class: "lbl-default-theme-description description"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "div-apply-theme-presentation-link" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["checked"]
const _hoisted_24 = { class: "position-relative" }
const _hoisted_25 = {
  id: "lblCheckBox",
  class: "lbl-default-theme-description description"
}
const _hoisted_26 = { class: "margin-top-10" }
const _hoisted_27 = {
  id: "lblUseThemeEfficiently",
  class: "description"
}
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "footer-tab border-radius-20" }
const _hoisted_30 = { class: "parent-div-buttons" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTheme = _resolveComponent("CustomTheme")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_DialogueTheme = _resolveComponent("DialogueTheme")!

  return (_ctx.isCustomThemePanelVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CustomTheme, {
          "custom-theme-data": _ctx.customThemeData,
          onHideCustomThemePanel: _ctx.HideCustomThemePanel
        }, null, 8, ["custom-theme-data", "onHideCustomThemePanel"])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "parent-dialogue-themes-tab",
        onKeypress: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
        onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
        onMousemove: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
        onTouchstart: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
      }, [
        (_ctx.showVueModalPopUp)
          ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
              key: 0,
              "modal-pop-up": _ctx.unsavedModalPopUp,
              "is-content-left-aligned": true,
              onClose: _ctx.CloseModelPopup,
              onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.DeleteModelPopUp($event)))
            }, null, 8, ["modal-pop-up", "onClose"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("EventSetting.Dialogues.ResponsesTheme")), 1),
                _createElementVNode("i", {
                  class: "fa fa-info-circle information-icon-tooltip font-size-12",
                  "aria-hidden": "true",
                  onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowHideInformationString(true))),
                  onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ShowHideInformationString(false)))
                }, null, 32),
                _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t("EventSetting.Dialogues.tooltip")), 513), [
                  [_vShow, _ctx.tooltipInfoVisible]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventSetting.Dialogues.SelectColorForCharts")), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("EventSetting.Dialogues.MaximumCustomisedThemesDescription")), 1),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.GetText()), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.showCharts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", {
                        class: "div-create-new-theme",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowCustomThemePanel(true)))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass([{'not-allowed' : (_ctx.totalThemes>=11)}, "create-new-theme cursor-pointer border-radius-10"])
                        }, [
                          _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_12)
                        ], 2),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("EventSetting.Dialogues.AddNewTheme")), 1)
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localDialogueThemes, (localDialogueTheme) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: localDialogueTheme.Id,
                          class: "div-custom-new-theme"
                        }, [
                          _createVNode(_component_DialogueTheme, {
                            "dialogue-theme": localDialogueTheme,
                            "applied-theme-id": _ctx.appliedThemeId,
                            "selected-theme-id": _ctx.localEventDetail.DialogueThemeId
                          }, null, 8, ["dialogue-theme", "applied-theme-id", "selected-theme-id"])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_15, [
                  (!_ctx.isDefaultTheme)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "inline-flex",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeDefaultThemeCheckBox()))
                      }, [
                        _createElementVNode("div", null, [
                          _createElementVNode("input", {
                            id: "inputDefaultThemeCheckBox",
                            type: "checkbox",
                            name: "makedefaulttheme",
                            checked: _ctx.markAsDefaultTheme
                          }, null, 8, _hoisted_16)
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("EventSetting.Dialogues.MakeDefaultTheme")), 1)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("EventSetting.Dialogues.DefaultThemeLabel")), 1),
                        _createElementVNode("img", { src: _ctx.greenTickIcon }, null, 8, _hoisted_20)
                      ]))
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", {
                    innerHTML: _ctx.$t('EventSetting.Dialogues.AppliedToPublicViewOnly')
                  }, null, 8, _hoisted_22)
                ]),
                _createElementVNode("div", {
                  class: "inline-flex",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeApplyThemeCheckBox()))
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("input", {
                      id: "inputApplyClientSideDialogueThemes",
                      type: "checkbox",
                      name: "applyClientSideDialogueThemes",
                      checked: _ctx.applyClientSideDialogueThemes
                    }, null, 8, _hoisted_23)
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("EventSetting.Dialogues.ApplyOnMobileAndWebApplication")), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _cache[13] || (_cache[13] = _createElementVNode("i", {
                  class: "fa fa-info-circle position-absolute font-info",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("EventSetting.Dialogues.UseThemeEfficiently")), 1),
                _createElementVNode("a", {
                  target: "_blank",
                  href: _ctx.learnMoreLink,
                  class: "hyper-link"
                }, _toDisplayString(_ctx.$t("EventSetting.Dialogues.LearnMore")), 9, _hoisted_28)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "display-flex" }, [
            _createElementVNode("div", { class: "width100" }, [
              _createElementVNode("hr", { class: "footer-hr margin-top-0 margin-top-15" })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default btn-cancelevent",
                disabled: _ctx.isDirtyObject,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.Discard()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
              ], 8, _hoisted_31)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default width100 btn-create-event",
                disabled: _ctx.isDirtyObject,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.Submit()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
              ], 8, _hoisted_32)
            ])
          ])
        ])
      ], 32))
}